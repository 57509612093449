.network {
  margin-top: 20px;
}

.network table button.btn {
  margin: 2px;
}


.network .sync-button {
	margin-bottom: 10px;
}