.jobs {
  margin-top: 20px;
}

.jobs table button.btn {
  margin: 2px;
}

.jobs .owner-dropdown {
	width: 250px;
	float: right;
	margin-bottom: 10px;
}