.jobtasks {
  margin-top: 20px;
}

.logs_hint {
	margin-bottom: 20px;
}

.jobtasks table button.btn {
  margin: 2px;
}

.jobtaskSawCommand {
  margin-left: 15px;
}

.filter-dropdown {
  max-width: 600px;
}

.filter-group {
  margin-left: 20px;
}

.log-alert {
  margin-left: 20px;
  max-width: 600px;
}

.load-all {
  margin-left: 20px !important;
}
.checkbox-all-container {
  text-align: center;
 
}
.checkbox-container {
  text-align: center;
  padding-top: 40px !important;
}

.header-container {
  max-width: 600px;
  
}

.header-container-small {
  max-width: 30%;
  
}

.filter-table td {
  border: 0px
}