.network-details {
  margin-top: 20px;
}

.emo-darwin-container {
  margin-top: 20px;
}

.emo-darwin-container table button.btn {
  margin: 2px;
}

.emo-darwin-container div.header {
    display: none;
    width:1024px;
    height:40px;
    margin: 80px auto 20px auto;
}

.emo-darwin-container div.main {
    clear:left;
    position: relative;
    top: 10px;
    /*left:30px;*/
    width:100%;
    /*height:1024px;*/
    margin: auto;
    border:2px solid #000;
	background: #fff;/* #c0c0c0; */
}

.emo-darwin-container div.network {
    clear:left;
    position: relative;
    width:100%;
    height:1024px;
    margin: auto;
	background: #fff;/* #c0c0c0; */
}

.emo-darwin-container div.level0 {
    clear:left;
}

.emo-darwin-container div.level1 {
    font-size:12px;    
}

.emo-darwin-container div.level2 {
    font-size:12px;    
}

.emo-darwin-container div.block {
    position: absolute;
    width:80px;
    height:80px;
    border:1px solid #000;
    border-radius: 5px;
    background: url('/emoDarwin/media/placeholder.gif') no-repeat center;
    cursor: pointer;
    margin:5px;
    font-size:11px;
    background-size: contain;
}

.emo-darwin-container div.jsoninput {
    position:relative;
    width:600px;
    display:none;
}
.jsoninput.active {
    display:block;
}

.listrow {
    clear:left;
}

.keyitem {
    float:left;
    width:100px;
    height:20px;
}
.valueitem {
    float:left;
    width:400px;
    height:20px;
}

.block_title {
    margin: 7px;
}

#modal-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .70;
    -webkit-opacity: .7;
    -moz-opacity: .7;
    filter: alpha(opacity=70);
    z-index: 1000;
}

#modal-content {
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    min-height: 240px;
    left: 50%;
    top: 50%;
    margin: -120px 0 0 -160px;
    padding: 10px;
    position: fixed;
    width: 640px;
    z-index: 1001;
}

#modal-background.active, #modal-content.active {
    display: block;
}

#info-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .50;
    -webkit-opacity: .5;
    -moz-opacity: .5;
    filter: alpha(opacity=50);
    z-index: 1000;
}

#info-content {
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    height: 480px;
    width: 640px;
    left: 10%;
    top: 10%;
    padding: 10px;
    position: fixed;
    overflow-y: scroll;
    /*width: 640px;*/
    z-index: 1001;
}

#info-background.active, #info-content.active {
    display: block;
}

.cmenu-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .30;
    -webkit-opacity: .3;
    -moz-opacity: .3;
    filter: alpha(opacity=30);
    z-index: 1000;
}

.cmenu {
    background-color: white;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    box-shadow: 0 0 20px 0 #222;
    -webkit-box-shadow: 0 0 20px 0 #222;
    -moz-box-shadow: 0 0 20px 0 #222;
    display: none;
    /*width: 200px;*/
    padding: 10px;
    position: absolute;
    /*overflow-y: scroll;*/
    z-index: 1001;
}


.cmenu-background.active, .cmenu.active {
    display: block;
}

.cm_item {
    clear:left;
    position:relative;
    height:30px;
    width:250px;
    text-align: left;
    vertical-align: middle;
    line-height: 30px;
    background: #fff;
    color: #000;
    border: 1px solid #666;
    margin: 0 0 -3 0;
    padding: 0 0 0 5px;
}
.cm_item:hover {
    background: #00c;
    color: #ccc;
}

button {
    background-color: #7f7;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius:6px;
    color: #000;
    font-family: 'Helvetica';
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    border:none;
}

button:disabled{
    background-color: #dfd;
    color:#666
}
button:hover {
    border: none;
    background:#0f0;
    box-shadow: 0px 0px 1px #777;

}

.vspace15 {
    clear:both;
    height:15px;
}
.vspace10 {
    clear:both;
    height:10px;
}
.vspace5 {
    clear:both;
    height:5px;
}
    
