
.App {
  margin-top: 15px;
  max-width: 80%;
  font-family: "PT Serif", serif;
}

.App .navbar-brand a{
  color: rgba(0,0,0,.5);
  text-decoration: none;
}

.react-pagination ul {
    display: inline-block;
}

.react-pagination li {
    padding-right: 5px;
    display: inline-block;
}
.react-pagination li.active {
	font-weight: bold;
}